import React, { Component, Fragment } from "react";
import Dhaka from "./dhaka.json";
import Chittagong from "./chittagong.json";
import Rajshahi from "./rajshahi.json";
import Khulna from "./khulna.json";
import Rangpur from "./rangpur.json";
import Mymensingh from "./mymensingh.json";
import Sylhet from "./sylhet.json";
import { Tab, Nav } from "react-bootstrap";
import Data from "./Data";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";
const Content = () => {
    const [preload, setPreload] = useState(true);
    const [data1, setdata1] = useState(Dhaka);
    const [data2, setdata2] = useState(Chittagong);
    const [data3, setdata3] = useState(Rajshahi);
    const [data4, setdata4] = useState(Khulna);
    const [data5, setdata5] = useState(Rangpur);
    const [data6, setdata6] = useState(Mymensingh);
    const [data7, setdata7] = useState(Sylhet);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a713b26ac35807d3a2c429"
        )
            .then((resultData) => {
                setdata1(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a7185678fc357870f08db1"
        )
            .then((resultData) => {
                setdata2(resultData.data);
            })
            .catch((err) => {
                console.log(err);
            });
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a7199978fc357870f08e07"
        )
            .then((resultData) => {
                setdata3(resultData.data);
            })
            .catch((err) => {
                console.log(err);
            });
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a71a5b78fc357870f08e44"
        )
            .then((resultData) => {
                setdata4(resultData.data);
            })
            .catch((err) => {
                console.log(err);
            });
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a71b1a78fc357870f08e6e"
        )
            .then((resultData) => {
                setdata5(resultData.data);
            })
            .catch((err) => {
                console.log(err);
            });
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a71bb578fc357870f08e86"
        )
            .then((resultData) => {
                setdata6(resultData.data);
            })
            .catch((err) => {
                console.log(err);
            });
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a71c1a78fc357870f08ea9"
        )
            .then((resultData) => {
                setdata7(resultData.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <Fragment>
            {preload && <Preload />}
            <div className="section">
                <div className="container">
                    <Tab.Container defaultActiveKey="tab1">
                        <div>
                            <Nav
                                as="ul"
                                varient="tabs"
                                style={{ justifyContent: "center" }}
                            >
                                <Nav.Item as="li">
                                    <Nav.Link
                                        eventKey="tab1"
                                        style={{
                                            color: "#602090",
                                            fontSize: "18px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Dhaka Division
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link
                                        eventKey="tab2"
                                        style={{
                                            color: "#602090",
                                            fontSize: "18px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Chittagong Division
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link
                                        eventKey="tab3"
                                        style={{
                                            color: "#602090",
                                            fontSize: "18px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Rajshahi Division
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link
                                        eventKey="tab4"
                                        style={{
                                            color: "#602090",
                                            fontSize: "18px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Khulna Division
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link
                                        eventKey="tab5"
                                        style={{
                                            color: "#602090",
                                            fontSize: "18px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Rangpur Division
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link
                                        eventKey="tab6"
                                        style={{
                                            color: "#602090",
                                            fontSize: "18px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Mymensingh Division
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link
                                        eventKey="tab7"
                                        style={{
                                            color: "#602090",
                                            fontSize: "18px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Sylhet Division
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <Tab.Content>
                            <Tab.Pane eventKey="tab1">
                                <div className="row">
                                    <Data data={data1} />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab2">
                                <div className="row">
                                    <Data data={data2} />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab3">
                                <div className="row">
                                    <Data data={data3} />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab4">
                                <div className="row">
                                    <Data data={data4} />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab5">
                                <div className="row">
                                    <Data data={data5} />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab6">
                                <div className="row">
                                    <Data data={data6} />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab7">
                                <div className="row">
                                    <Data data={data7} />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </Fragment>
    );
};

export default Content;
