import React, { Component, Fragment } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";

const Data = ({ data }) => {
    const Data = data.map((item, i) => {
        return (
            <div
                className='col-lg-4 col-md-6 col-sm-12'
                key={i}
                style={{ marginBottom: "10px" }}
            >
                <Card className='text-center'>
                    <Card.Header
                        style={{ backgroundColor: "#413565", color: "#fff" }}
                    >
                        {item.header}
                    </Card.Header>
                    <Card.Body>
                        <Card.Text style={{ color: "#000" }}>
                            {item.address}
                        </Card.Text>
                        <Card.Text style={{ color: "#000" }}>
                            {item.tel}
                        </Card.Text>
                        {/* <Button variant="primary">Go somewhere</Button> */}
                    </Card.Body>
                    <Card.Title style={{ color: "#000" }}>
                        {item.place}
                    </Card.Title>
                </Card>
            </div>
        );
    });
    return Data;
};

export default Data;
